import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import facebookIcon from "../assets/platform/facebook_icon.svg";
import instagramIcon from "../assets/platform/instagram_icon.svg";
import twitterIcon from "../assets/platform/twitter_icon.svg";
import youtubeIcon from "../assets/platform/youtube_icon.svg";
import FormLabel from "../components/FormLabel";
import ServiceCheck from "../components/ServiceCheck";
import {
  AppSelectBox,
  BorderBox,
  DefaultLayout,
  FormButton,
  FormInput,
} from "../components/styles";
import { ClassName } from "../GlobalStyles";
import useCategory from "../hooks/useCategory";
import useCreateOrder from "../hooks/useCreateOrder";
import useServices from "../hooks/useService";
import useViewPort from "../hooks/useViewPort";
import { hideLoading, showLoading } from "../Loading";
import NoticePiece from "../pieces/NoticePiece";
import { AccountDB, CategoryDB } from "../types";
import { krwFormat, removeCuntryEmoji } from "../utils";

const PlatformHeader = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: 880px) {
    margin-bottom: 5px;
    font-size: 14px;
  }
`;
const PlatformContainer = styled.div`
  display: flex;
  gap: 0 20px;

  @media (max-width: 880px) {
    gap: 0 15px;
  }
`;
const Container = styled.div``;
const NoticePieceMargin = styled.div`
  margin-top: 10px;
`;
const OrderFormContainer = styled.div`
  margin-top: 50px;
`;
const FormItemContainer = styled.div`
  & + & {
    margin-top: 25px;
  }
`;
const ServiceDescription = styled.div`
  border: 1px solid #d9d9d9;
  padding: 15px;
  border-radius: 20px;
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;
const AmoutDescription = styled.span`
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: normal;
  color: #737373;
`;
const TotalPrice = styled.div`
  height: 40px;
  background-color: #e5e5e5;
  width: 100%;
  font-size: 14px;
  color: #555;
  border-radius: 20px;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
`;
const ServiceCheckMargin = styled.div`
  margin-top: 20px;
`;

interface FormState {
  amount: number;
  link: string;
}

interface AutoState {
  runs: number;
  interval: number;
}

const replaceStringMap: Record<number, string> = {
  3: "👫🏻 ",
  4: "💕 ",
  5: "👫🏿 ",
  6: "💗 ",
};

const OrderPage: FC = () => {
  const [platform, setPaltform] = useState("");
  const { data: categorys = [] } = useCategory();
  const { data: services = [] } = useServices({ includeWon: true });
  const [selectCategoryId, setSelectCategoryId] = useState<number | null>(null);
  const [selectServiceId, setSelectServiceId] = useState<number | null>(null);
  const [autoState, setAutoState] = useState<AutoState | null>(null);
  const [autoMode, setAutoMode] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const isMobile = useViewPort((viewport) => viewport < 880);
  const profile: AccountDB = queryClient.getQueryData("profile")!;
  const navigate = useNavigate();

  const { mutate: createOrderApi } = useCreateOrder({
    onSuccess: () => {
      hideLoading();
      toast.success("주문에 성공했습니다.");
      navigate("/order-history");
      queryClient.refetchQueries("profile");
    },
    onError: () => {
      hideLoading();
      toast.error("주문에 실패했습니다.");
    },
  });

  const [formState, setFormState] = useState<FormState>({
    amount: 0,
    link: "",
  });

  const changePlatformCallback = useCallback((value: string) => {
    return () => {
      setPaltform(value);
    };
  }, []);

  const targetCategorys = useMemo(() => {
    const removeEmoji = (categorys: CategoryDB[]) => {
      return categorys.map((category) => {
        return {
          ...category,
          text: removeCuntryEmoji(category.text, replaceStringMap[category.id]),
        };
      });
    };

    if (platform.length === 0) {
      if (!isMobile) {
        return removeEmoji(categorys);
      }
      return categorys;
    }

    const targetCategorys = categorys.filter(
      (item) => item.platform === platform
    );

    if (!isMobile) return removeEmoji(targetCategorys);
    return targetCategorys;
  }, [platform, categorys, isMobile]);

  const targetServices = useMemo(() => {
    if (services.length === 0) return [];
    const targetCategory = categorys.find(
      (item) => item.id === selectCategoryId
    );

    const categoryServices = targetCategory?.services ?? [];

    const targetServices =
      categoryServices
        .map((serviceId) => services.find((item) => item.id === serviceId)!)
        .filter((item) => item !== undefined) ?? [];

    if (isMobile) return targetServices;

    return targetServices.map((service) => {
      return {
        ...service,
        text: removeCuntryEmoji(service.text),
      };
    });
  }, [services, selectCategoryId, categorys, isMobile]);

  const targetService = useMemo(() => {
    return targetServices.find((item) => item.id === selectServiceId);
  }, [targetServices, selectServiceId]);

  const createOrder = () => {
    if (targetService === undefined) {
      toast.error("서비스를 선택해 주세요.");
      return;
    }

    if (formState.link.length === 0) {
      toast.error("주문 링크를 입력해주세요.");
      return;
    }

    if (formState.amount < targetService.min) {
      toast.error("주문 수량이 최소수량보다 적습니다.");
      return;
    }
    if (formState.amount > targetService.max) {
      toast.error("주문 수량이 최대수량보다 많습니다.");
      return;
    }
    if (autoMode) {
      if (autoState!.runs < 1) {
        toast.error("자동주문 횟수는 1이상 이여야 합니다.");
        return;
      }
      if (autoState!.runs < 1) {
        toast.error("자동주문 간격은 1이상 이여야 합니다.");
        return;
      }
    }
    const totalPoint =
      targetService.point * formState.amount * (autoState?.runs ?? 1);

    if (profile.point < totalPoint) {
      toast.error("포인트가 부족합니다.");
      return;
    }

    showLoading();
    createOrderApi({
      amount: Number(formState.amount),
      link: formState.link,
      serviceId: targetService.id,
    });
  };

  const changeAutoState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, valueAsNumber } = e.target;

    setAutoState((prev) => ({
      ...prev!,
      [name]: valueAsNumber,
    }));
  }, []);

  const changeFormState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "amount") {
      const numValue = Number(value);

      setFormState((prev) => ({
        ...prev,
        amount: numValue,
      }));
      return;
    }

    setFormState((prev) => ({
      ...prev,
      link: value,
    }));
  }, []);

  const changeAutoMode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAutoMode(e.target.checked);
  }, []);

  useEffect(() => {
    setSelectCategoryId(targetCategorys[0]?.id ?? null);
  }, [targetCategorys]);

  useEffect(() => {
    setSelectServiceId(targetServices[0]?.id ?? null);
  }, [targetServices]);

  useEffect(() => {
    setAutoMode(false);
    if (targetService === undefined) return;
    setFormState((prev) => ({
      ...prev,
      amount: targetService.min,
    }));
  }, [targetService]);

  useEffect(() => {
    if (!autoMode) {
      setAutoState({ interval: 1, runs: 1 });
    }
  }, [autoMode]);

  return (
    <Container>
      <DefaultLayout>
        <ServiceCheckMargin>
          <ServiceCheck />
        </ServiceCheckMargin>
        <NoticePieceMargin>
          <NoticePiece />
        </NoticePieceMargin>
        <BorderBox className={ClassName.shadow01}>
          <PlatformHeader>플랫폼을 선택해주세요</PlatformHeader>
          <PlatformContainer>
            <PlatformItem
              active={platform === "인스타그램"}
              text="인스타그램"
              onClick={changePlatformCallback("인스타그램")}
              iconSrc={instagramIcon}
              backgroundColor="#d6249f  radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
            />
            <PlatformItem
              active={platform === "페이스북"}
              onClick={changePlatformCallback("페이스북")}
              text="페이스북"
              iconSrc={facebookIcon}
              backgroundColor="#1f55b1"
            />
            <PlatformItem
              text="유튜브"
              iconSrc={youtubeIcon}
              active={platform === "유튜브"}
              onClick={changePlatformCallback("유튜브")}
              backgroundColor="#ec1d1b"
            />
            <PlatformItem
              text="기타"
              backgroundColor="#00acee"
              onClick={changePlatformCallback("기타")}
              iconSrc={twitterIcon}
              active={platform === "기타"}
            />
          </PlatformContainer>
          <OrderFormContainer>
            <FormItemContainer>
              <FormLabel
                label="1단계"
                text="이용하실 서비스 목록을 선택해 주세요."
              />
              <AppSelectBox
                value={selectCategoryId ?? undefined}
                onChange={(e) => {
                  setSelectCategoryId(Number(e.target.value));
                }}
              >
                {targetCategorys.map((item) => (
                  <option
                    key={isMobile ? item.id : `${item.id}-1`}
                    value={item.id}
                  >
                    {item.text}
                  </option>
                ))}
              </AppSelectBox>
            </FormItemContainer>
            <FormItemContainer>
              <FormLabel
                label="2단계"
                text="구매하실 상품 목록을 선택해 주세요."
              />
              <AppSelectBox
                onChange={(e) => {
                  setSelectServiceId(Number(e.target.value));
                }}
              >
                {targetServices.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.text}
                  </option>
                ))}
              </AppSelectBox>
            </FormItemContainer>
            <FormItemContainer>
              <FormLabel label="3단계" text="해당 상품에 대한 설명 입니다." />
              <ServiceDescription>{targetService?.notice}</ServiceDescription>
            </FormItemContainer>
            <FormItemContainer>
              <FormLabel label="4단계" text="주문링크를 입력해 주세요." />
              <FormInput
                disabled={targetService?.isReadOnly}
                name="link"
                value={formState.link}
                onChange={changeFormState}
              />
            </FormItemContainer>
            <FormItemContainer>
              <FormLabel label="5단계" text="구매 수량을 입력해 주세요." />
              <FormInput
                disabled={targetService?.isReadOnly}
                name="amount"
                type="number"
                value={formState.amount === 0 ? undefined : formState.amount}
                onChange={changeFormState}
              />
              <AmoutDescription>
                최소 주문가능 수량: {targetService?.min ?? 0} - 최대 주문가능
                수량:
                {targetService?.max ?? 0}
              </AmoutDescription>
            </FormItemContainer>
            <FormItemContainer>
              <FormLabel text="주문금액" />
              <TotalPrice>
                {krwFormat(
                  targetService?.point
                    ? targetService.point *
                        formState.amount *
                        (autoState?.runs ?? 1)
                    : 0,
                  { useWon: true }
                )}
              </TotalPrice>
            </FormItemContainer>
            <FormButton
              disabled={targetService?.isReadOnly}
              onClick={createOrder}
            >
              주문하기
            </FormButton>
          </OrderFormContainer>
        </BorderBox>
      </DefaultLayout>
    </Container>
  );
};

const PlatformItemContainer = styled.div<{
  backgroundSrc?: string;
  backgroundColor?: string;
  active: boolean;
}>`
  flex: 1;
  cursor: pointer;
  user-select: none;
  aspect-ratio: 1;
  width: 50%;
  border-radius: 20%;
  box-sizing: border-box;
  background: ${({ backgroundColor, backgroundSrc }) =>
    backgroundSrc ? `url(${backgroundSrc}) no-repeat` : backgroundColor};
  background-size: contain;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
  opacity: ${(props) => (props.active ? "1.0" : "0.6")};
  transition: 300ms all;

  div {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
  }

  @media (max-width: 880px) {
    gap: 5px 0;
    div {
      font-size: 11px;
    }
  }
`;

const PlatFormImageContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  max-height: 50%;

  img {
    height: 100%;
  }

  @media (max-width: 880px) {
    max-height: 40%;
  }
`;

const PlatformItem: FC<{
  backgroundSrc?: string;
  backgroundColor?: string;
  iconSrc: string;
  text: string;
  onClick?: VoidFunction;
  active?: boolean;
}> = ({
  backgroundSrc,
  backgroundColor,
  text,
  iconSrc,
  onClick,
  active = false,
}) => {
  return (
    <PlatformItemContainer
      backgroundSrc={backgroundSrc}
      backgroundColor={backgroundColor}
      onClick={onClick}
      active={active}
    >
      <PlatFormImageContainer>
        <img src={iconSrc} />
      </PlatFormImageContainer>
      <div>{text}</div>
    </PlatformItemContainer>
  );
};

export default OrderPage;
